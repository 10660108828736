import { useEffect, useState } from 'react';

import carrousselImg from '../images/stockage/7.jpeg';
import camionImg from '../images/camion(1).png';
import camionDuoImg from '../images/roadtrain(1).png';
import camionMegaImg from '../images/camion.png';
import camionMega2img from '../images/camionfosse.jpeg';
import camionMega3img from '../images/camionfosse2.jpeg';
import i18next from 'i18next';

export default (props) => {
    return (
        <div className="page">
            <div className='flotte _1'>
                <div className="pillow"></div>
                <h1 className="title">{i18next.t("flotte_1_title")}</h1>
                <p>{i18next.t("flotte_1_desc")}</p>
            </div>
            <h3 className="flotte">{i18next.t("flotte_h3")}</h3>
            <div className="flotte _2 article">
                <div>
                    {/* <h1>1.</h1> */}
                    <h2>{i18next.t("flotte_article_1_title")}</h2>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_1_desc1")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_1_desc2")}}></p>
                </div>
                <div className="image">
                    <img src={camionImg} alt="image of a Coilmulde truck" />
                </div>
            </div>
            <div className="flotte _3 articlei">
                <div>
                    {/* <h1>2.</h1> */}
                    <h2>{i18next.t("flotte_article_2_title")}</h2>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_2_desc1")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_2_desc2")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_2_desc3")}}></p>
                </div>
                <div className="image">
                    <img src={camionMegaImg} alt="image of a Coilmulde truck" />
                </div>
            </div>
            <div className="flotte _3 article">
                <div>
                    {/* <h1>3.</h1> */}
                    <h2>{i18next.t("flotte_article_3_title")}</h2>
                    <ul dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_3_desc1")}}>  
                    </ul>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_3_desc2")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("flotte_article_3_desc3")}}></p>
                </div>
                <div className="image">
                    <img className="longtruck" src={camionDuoImg} alt="image of a Coilmulde truck" />
                </div>
            </div>
            <div className="flotte _4 articlei">
                <div>
                    {/* <h1>4.</h1> */}
                    <h2>{i18next.t("flotte_article_4_title")}</h2>
                    <p>{i18next.t("flotte_article_4_desc")}</p>
                </div>
                <div className="images">
                    <img src={carrousselImg} alt="image of a Coilmulde truck" />
                    <img src={camionMega2img} alt="image of a Coilmulde truck" />
                    <img src={camionMega3img} alt="image of a Coilmulde truck" />
                </div>
            </div>
        </div>
    );
}
