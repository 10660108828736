import i18next from "i18next";

i18next.init({
    lng: 'fr',
    debug: true,
    resources: {
        en: {
            "translation": {
                "logo": "ATV LOGISTICS",
                "menu_1": "HOME",
                "menu_2": "STORAGE & LOGISTICS",
                "menu_3": "ROAD TRANSPORT & FLEET",
                "menu_4": "ABOUT US",
                "service_compta": "Accounting service:",
                "address_1": "Depot: Rue de l'Abbaye 10b, B-4040 HERSTAL, Belgium",
                "address_2": "Head Office: Rue Commandant Naessens, 47, B-4431 LONCIN, Belgium",
                "condition_gen": "General conditions",
                "and": "and",
                "home_image": "Your specialist in national and international road transport Storage and Handling",
                "home_spec_line1": "Specialized in road transport of all kinds of goods, ATV LOGISTICS covers almost all European destinations, including Turkey.",
                "home_spec_line2": "In partnership with ArcelorMittal and New La Chaussée, we also offer storage, handling, and logistics management services.",
                "home_spec_line3": "With 40 years of experience in the steel industry in Belgium and 20 years in transport, we offer efficient, safe, and competitive solutions.",
                "home_spec_line4": "Our organization is distinguished by its simplicity, availability, and flexibility, with a commercial and operational service available in seven languages to optimize your costs and customer service.",
                "home_spec_line5": "For more information, do not hesitate to contact us.",
                "home_bubble_1_title": "SPECIALIST IN ROAD TRANSPORT AND FREIGHT FORWARDING",
                "home_bubble_1_desc": "Our transport specialists offer you a complete expertise of your needs<br/><br/>And provide the appropriate solutions, with competitive prices.<br/><br/>From the pallet to the full truck",
                "home_bubble_2_title": "REAL-TIME TRANSPORT TRACKING",
                "home_bubble_2_desc": "Thanks to our transport experts, we ensure real-time tracking of your transports.<br/><br/>Our specialists are in direct contact with the drivers.",
                "home_bubble_3_title": "LOGISTICS PLATFORM",
                "home_bubble_3_desc": "Located at the heart of the BENELUX - FRANCE - GERMANY motorway network<br/><br/>1500 M2 OF STORAGE SPACE (over 500 RACKS)<br/><br/>Truck parking: 700 M2",
                "home_dest": "Our main destinations",
                "home_fr": "France",
                "home_bl": "Belgium",
                "home_nl": "Netherlands",
                "home_de": "Germany",
                "home_pl": "Poland",
                "home_it": "Italy",
                "home_es": "Spain",
                "home_dk": "Denmark",
                "home_hu": "Hungary",
                "home_cz": "Czech Republic",
                "home_sk": "Slovakia",
                "home_lt": "Lithuania",
                "home_ee": "Estonia",
                "home_lv": "Latvia",
                "home_ro": "Romania",
                "home_fi": "Finland",
                "home_bg": "Bulgaria",
                "home_tr": "Turkey",
                "home_avatar_1_title":"Bernard",
                "home_avatar_1_desc": 'France Specialist <i class="flag flag-fr"></i><br/>Poland <i class="flag flag-pl"></i>',
                "home_avatar_2_title": "Maria",
                "home_avatar_2_desc": 'Italy Specialist <i class="flag flag-it"></i>',
                "home_avatar_3_title": "Dmytro",
                "home_avatar_3_desc": 'Ukraine Specialist <i class="flag flag-ua"></i>',
                "home_avatar_4_title": "Olivier",
                "home_avatar_4_desc": 'Transport, logistics and freight manager <i class="flag flag-be"></i> <i class="flag flag-germany"></i>',
                "home_avatar_5_title": "Johnny",
                "home_avatar_5_desc": 'Finland Specialist <i class="flag flag-fi"></i>',
                "home_avatar_6_title": "Esin",
                "home_avatar_6_desc": 'Turkey Specialist <i class="flag flag-tr"></i>',
                "home_avatar_7_title": "Martin",
                "home_avatar_7_desc": 'Bulgaria Specialist <i class="flag flag-bg"></i>',
                "storage_title": "Storage & Logistics",
                "storage_title_h3": "Presentation of our facilities",
                "storage_1_center_line1": "<span>1500 m2</span> of storage space (over <span>500 racks</span>)",
                "storage_1_center_line2": "Truck parking: <span>700 m2</span>",
                "storage_1_center_line3": "Transport ATV Logistics, Your Partner in Transport and Logistics of General Goods throughout Europe.",
                "storage_2_sep_1_title": "Storage Facility",
                "storage_2_sep_1_desc": "Our storage facility is equipped to meet your storage needs securely.<br/> With a total area of <span>1500 m2</span> and over <span>500 racks</span>, we can efficiently manage a variety of goods.",
                "storage_2_sep_2_title": "Company Location",
                "storage_2_sep_2_desc": "We are located in a strategic location that allows us to efficiently serve our customers across Europe.<br /> Our location provides easy access to major transport routes, facilitating the logistics of your goods.",
                "flotte_1_title": "Road Transport & Fleet",
                "flotte_1_desc": "Our logistics experts analyze your transport needs in detail and offer customized solutions at competitive rates. Specialized in road transport of all types of cargo, we handle shipments from a single pallet to a fully loaded truck. We adjust our services to ensure fast and safe delivery across almost all of Europe.",
                "flotte_h3": "Presentation of our different trucks",
                "flotte_article_1_title": "1. Tautliner tarp trailer",
                "flotte_article_1_desc1": "Length: <span>13.60 m</span>, Width: <span>2.45 m</span>, Height: <span>2.75 m</span>",
                "flotte_article_1_desc2": "Maximum weight: <span>24 tons</span>",
                "flotte_article_2_title": "2. Mega trailer",
                "flotte_article_2_desc1": "Length: <span>13.60 m</span>, Width: <span>2.45 m</span>, Height: <span>3 m</span>",
                "flotte_article_2_desc2": "Available volume: <span>100 m3</span>",
                "flotte_article_2_desc3": "Maximum weight: <span>24 tons</span>",
                "flotte_article_3_title": "3. Jumbo or Road train",
                "flotte_article_3_desc1": "<li>Length: <span>15.40 m</span></li>\
                <li>Width: <span>2.45 m</span></li>\
                <li>Height: <span>3 m</span></li>",
                "flotte_article_3_desc2": "Available volume: <span>120 m3</span>",
                "flotte_article_3_desc3": "Maximum authorized weight: <span>22 tons</span>",
                "flotte_article_4_title": "4. Coil trucks (coilmulde truck)",
                "flotte_article_4_desc": "Used for steel or paper coils.",
                "apropos_data_1_title": "EXPERTISE",
                "apropos_data_1_desc": "We are experts in freight forwarding for over 10 years.",
                "apropos_data_2_title": "CUSTOMER SERVICE",
                "apropos_data_2_desc": "Our dedicated team is available 24/7 to meet your needs and provide exceptional service.",
                "apropos_data_3_title": "DELIVERY SPEED",
                "apropos_data_3_desc": "We guarantee fast delivery times to meet your strictest transport requirements.",
                "apropos_data_4_title": "COMPETITIVE PRICES",
                "apropos_data_4_desc": "Our rates are competitive in the freight forwarding market while maintaining service quality.",
                "apropos_data_5_title": "PERMANENT TRACKING",
                "apropos_data_5_desc": "We ensure permanent tracking of your shipments to keep you informed at every stage of the process.",
                "apropos_data_6_title": "ISO 9001 CERTIFICATION",
                "apropos_data_6_desc": "In the process of obtaining for a permanent improvement in the quality of our services to our customers.",
                "apropos_2_image_title": "About <span>us</span>",
                "apropos_2_image_desc": 'Welcome to ATV Logistics, a family business established in Liège, Belgium in 2012 <i class="flag flag-be"></i><br/> your trusted partner in transport and freight forwarding',
                "apropos_3_hist_title": "Company history",
                "apropos_3_hist_desc": "Founded by Jean Vanderwaeren, a seasoned professional with over 40 years of experience in the steel industry, and Olivier Vanderwaeren, a graduate of the University of Liège in Management Sciences with 20 years of expertise in the field, ATV Logistics benefits from enlightened leadership and a solid strategic vision.<br/>\
                \
                Thanks to his exceptional career in the steel industry and his engineering background from the School of Engineering of Mons, Jean Vanderwaeren brings in-depth technical expertise and a thorough understanding of the logistical and operational challenges encountered in industrial sectors. His passion for innovation and commitment to excellence guide our approach to transport, ensuring effective and tailored solutions for each client.<br/>\
                \
                Alongside Jean, Olivier brings a strategic perspective and business management expertise acquired during his studies at the University of Liège. His mastery of management sciences and his ability to analyze market trends allow us to anticipate our clients' needs and develop innovative logistics solutions.<br/>\
                \
                At ATV Logistics, we are committed to providing superior transport services, supported by a passionate and dedicated team. Our goal is to become your preferred partner for all your transport and freight forwarding needs, offering reliable, flexible solutions focused on your business objectives.<br/>\
                \
                We understand the importance of flexibility in the transport sector. That's why we are available and easily reachable to meet your specific needs. Whether by email or phone, our team always responds quickly and is available to discuss your needs, provide advice, and find solutions tailored to your business.<br/>\
                \
                Contact us today to discover how ATV Logistics can help you achieve your logistics goals successfully.<br/>\
                \
                Thank you for your trust in ATV Logistics. We look forward to serving you.",
                "apropos_3_part_title": "Our partners",
                "apropos_3_val_title": "Our values",
                "apropos_3_val_desc": "We emphasize several points that are very important to us",
                "condition_desc": "All our services are subject to the General Conditions of Belgian Forwarding of 2005.\
                The text of these conditions was published in the annex to the Belgian Official Gazette on June 24, 2005, under number 0090237 and is available upon request. It can also be viewed online on our website.\
                \
                Our invoices are strictly payable on the due date.\
                Any complaint must be made by registered mail within 8 days.\
                Unpaid invoices on the due date will automatically bear interest at the rate of 12% per annum, as well as a conventional compensation of 5%. Late payment interest is automatically due, by right and without prior notice, from the due date of the invoice.\
                \
                In case of dispute, the courts of Liège shall have exclusive jurisdiction."
            }
        },       
        fr: {
            translation: {
                "logo": "ATV LOGISTICS",
                "menu_1": "ACCUEIL",
                "menu_2": "STOCKAGE & LOGISTIQUE",
                "menu_3": "TRANSPORT ROUTIER & FLOTTE",
                "menu_4": "A PROPOS DE NOUS",
                "service_compta": "Service comptabilité :",
                "address_1": "Dépôt : Rue de l'Abbaye 10b, B-4040 HERSTAL, Belgique",
                "address_2": "Siège Social : Rue Commandant Naessens, 47 . B-4431 LONCIN, Belgique",
                "condition_gen": "Conditions Générales",
                "and": "et",
                "home_image": "Votre spécialiste du transport routier national et international Stockage et Manutention",
                "home_spec_line1": "Spécialisé dans le transport routier de marchadises de toutes sortes, ATV LOGISTICS couvre quasi toutres les destinations européennes, incluant la Turquie.",
                "home_spec_line2": "En partenariat avec ArcelorMittal et New La Chaussée, nous offrons également des services d'entreposage, de manutention et de gestion logistique.",
                "home_spec_line3": "Forts de 40 ans d'expérience dans le secteur sidérurgique en Belgique et de 20 ans dans le transport, nous proposons des solutions efficaces, sûres et compétitives.",
                "home_spec_line4": "Notre organisation se distingue par sa simplicité, sa disponibilité et sa flexibilité, avec un service commercial et opérationnel disponible en sept langues pour optimiser vos coûts et votre service clients.",
                "home_spec_line5": "Pour plus d'informations, n'hésitez pas à nous contacter.", 
                "home_bubble_1_title": "SPECIALISTE DU TRANSPORT ROUTIER ET AFFRETEMENT",
                "home_bubble_1_desc": "Nos spécialistes transport vous proposent une expertise complète de vos besoins<br/><br/>\
                Et vous offrent les solutions adaptées, avec des prix compétitifs.<br/><br/>\
                De la palette au camion complet",
                "home_bubble_2_title":"SUIVI DE VOS TRANSPORTS EN TEMPS REELS",
                "home_bubble_2_desc":"Grâce à nos experts transport, nous assurons un suivi de vos transports en temps réels.<br/><br/>\
                Nos spécialistes sont en contact direct avec les chauffeurs.",
                "home_bubble_3_title": "PLATEFORME LOGISTIQUE",
                "home_bubble_3_desc": "Installé au cœur du réseau autoroutier BENELUX -FRANCE – ALLEMAGNE<br/><br/>\
                1500 M2 DE SURFACE DE STOCKAGE  ( + de 500 RACKS )<br/><br/>\
                Parking poids-lourd : 700 M2",
                "home_dest": "Nos principales destinations",
                "home_fr": "France",
                "home_bl": "Belgique",
                "home_nl": "Pays-Bas",
                "home_de": "Allemagne",
                "home_pl": "Pologne",
                "home_it": "Italie",
                "home_es": "Espagne",
                "home_dk": "Danemark",
                "home_hu": "Hongrie",
                "home_cz": "Tchéquie",
                "home_sk": "Slovaquie",
                "home_lt": "Lituanie",
                "home_ee": "Estonie",
                "home_lv": "Lettonie",
                "home_ro": "Roumanie",
                "home_fi": "Finlande",
                "home_bg": "Bulgarie",
                "home_tr": "Turquie",
                "home_avatar_1_title":"Bernard",
                "home_avatar_1_desc": 'Spécialiste France <i class="flag flag-fr"></i><br/>Pologne <i class="flag flag-pl"></i>',
                "home_avatar_2_title": "Maria",
                "home_avatar_2_desc": 'Spécialiste Italie <i class="flag flag-it"></i>',
                "home_avatar_3_title": "Dmytro",
                "home_avatar_3_desc": 'Spécialiste Ukraine <i class="flag flag-ua"></i>',
                "home_avatar_4_title": "Olivier",
                "home_avatar_4_desc": 'Responsable transport,<br/>logistique et affrètement <i class="flag flag-be"></i> <i class="flag flag-germany"></i>',
                "home_avatar_5_title": "Johnny",
                "home_avatar_5_desc": 'Spécialiste Finlande <i class="flag flag-fi"></i>',
                "home_avatar_6_title": "Esin",
                "home_avatar_6_desc": 'Spécialiste Turquie <i class="flag flag-tr"></i>',
                "home_avatar_7_title": "Martin",
                "home_avatar_7_desc": 'Spécialiste Bulgarie <i class="flag flag-bg"></i>',
                "storage_title": "Stockage & Logistique",
                "storage_title_h3": "Présentation de nos installations",
                "storage_1_center_line1": "<span>1500 m2</span> de surface de stockage (+ de <span>500 racks</span>)",
                "storage_1_center_line2": "Parking poids lourds : <span>700 m2</span>",
                "storage_1_center_line3": "Transport ATV Logistics, Votre Partenaire Transport et Logistique en Marchandises Générales sur Toute l’Europe.",
                "storage_2_sep_1_title": "Installation de Stockage",
                "storage_2_sep_1_desc": "Notre installation de stockage est équipée pour répondre à vos besoins de stockage en toute sécurité.<br/> Avec une surface totale de <span>1500 m2</span> et plus de <span>500 racks</span>, nous pouvons gérer efficacement une variété de marchandises.",
                "storage_2_sep_2_title": "Emplacement de l'Entreprise",
                "storage_2_sep_2_desc": "Nous sommes situés dans un endroit stratégique qui nous permet de desservir efficacement nos clients dans toute l'Europe.<br /> Notre emplacement bénéficie d'un accès facile aux principales voies de transport, ce qui facilite la logistique de vos marchandises.",
                "flotte_1_title": "Transport routier & Flotte",
                "flotte_1_desc": "Nos experts en logistique analysent en détail vos besoins de transport et vous proposent des solutions personnalisées à des tarifs compétitifs. Spécialisés dans le transport routier de tous types de cargaisons, nous prenons en charge des envois depuis une simple palette jusqu'à un camion entièrement chargé. Nous ajustons nos services pour assurer une livraison à la fois rapide et sûre à travers presque toute l'Europe.",
                "flotte_h3": "Présentation de nos différents camions",
                "flotte_article_1_title": "1. Remorque Tautliner bâchée",
                "flotte_article_1_desc1": "Longueur: <span>13.60 m</span>, Largeur: <span>2.45 m</span>, Hauteur: <span>2.75 m</span>",
                "flotte_article_1_desc2": "Poids maximum: <span>24 tonnes</span>",
                "flotte_article_2_title": "2. Remorque Méga",
                "flotte_article_2_desc1": "Longueur: <span>13.60 m</span>, Largeur: <span>2.45 m</span>, Hauteur: <span>3 m</span>",
                "flotte_article_2_desc2": "Volume disponible: <span>100 m3</span>",
                "flotte_article_2_desc3": "Poids maximum: <span>24 tonnes</span>",
                "flotte_article_3_title": "3. Jumbo ou Road train",
                "flotte_article_3_desc1": "<li>Longueur: <span>15.40 m</span></li>\
                <li>Largeur: <span>2.45 m</span></li>\
                <li>Hauteur: <span>3 m</span></li>",
                "flotte_article_3_desc2": "Volume disponible: <span>120 m3</span>",
                "flotte_article_3_desc3": "Poids maximum autorisé: <span>22 tonnes</span>",
                "flotte_article_4_title": "4. Camions à fosses (coilmulde truck)",
                "flotte_article_4_desc": "Utilisés pour les bobines d’acier ou de papier.",
                "apropos_data_1_title": "EXPERTISE",
                "apropos_data_1_desc": "Nous sommes des experts dans le domaine de l'affrètement depuis plus de 10 ans.",
                "apropos_data_2_title": "SERVICE CLIENT",
                "apropos_data_2_desc": "Notre équipe dévouée est disponible 24/7 pour répondre à vos besoins et vous offrir un service exceptionnel.",
                "apropos_data_3_title": "RAPIDITÉ DE LIVRAISON",
                "apropos_data_3_desc": "Nous garantissons des délais de livraison rapides pour répondre à vos exigences de transport les plus strictes.",
                "apropos_data_4_title": "PRIX COMPÉTITIFS",
                "apropos_data_4_desc": "Nos tarifs sont compétitifs sur le marché de l'affrètement tout en maintenant la qualité du service.",
                "apropos_data_5_title": "SUIVI PERMANENT",
                "apropos_data_5_desc": "Nous assurons un suivi permanent de vos expéditions pour vous tenir informé à chaque étape du processus.",
                "apropos_data_6_title": "CERTIFICATION ISO 9001",
                "apropos_data_6_desc": "En cours d'obtention en vue d'une amélioration permanente de la qualité de nos services envers nos clients.",
                "apropos_2_image_title": "A propos de <span>nous</span>",
                "apropos_2_image_desc": 'Bienvenue chez ATV Logistics, une société familiale créée à Liège en Belgique en 2012 <i class="flag flag-be"></i><br/> votre partenaire de confiance dans le domaine du transport et de l\'affrètement',
                "apropos_3_hist_title": "Historique de la société",
                "apropos_3_hist_desc": "Fondée par Jean Vanderwaeren, un professionnel chevronné avec plus de 40 ans d'expérience dans l'industrie de la sidérurgie, et Olivier Vanderwaeren, diplômé de l'Université de Liège en Sciences de Gestion et fort de 20 ans d'expertise dans le domaine, ATV Logistics  bénéficie d'un leadership éclairé et d'une vision stratégique solide.<br/>\
                \
                Grâce à son parcours exceptionnel dans le secteur de la sidérurgie et à sa formation d'ingénieur de l'École d'Ingénieur de Mons, Jean Vanderwaeren apporte une expertise technique pointue et une connaissance approfondie des défis logistiques et opérationnels rencontrés dans les secteurs industriels. Sa passion pour l'innovation et son engagement envers l'excellence guident notre approche du transport, en garantissant des solutions efficaces et adaptées à chaque client.<br/>\
                \
                Aux côtés de Jean, Olivier apporte une perspective stratégique et une expertise en gestion d'entreprise acquises lors de ses études à l'Université de Liège. Sa maîtrise des sciences de gestion et sa capacité à analyser les tendances du marché nous permettent d'anticiper les besoins de nos clients et de développer des solutions logistiques innovantes.<br/>\
                \
                Chez ATV Logistics, nous nous engageons à fournir des services de transport de qualité supérieure, soutenus par une équipe passionnée et dévouée. Notre objectif est de devenir votre partenaire privilégié pour tous vos besoins en transport et affrètement, en offrant des solutions fiables, flexibles et axées sur vos objectifs commerciaux.<br/>\
                \
                Nous comprenons l'importance de la flexibilité dans le domaine du transport. C'est pourquoi nous sommes disponibles et facilement joignables pour répondre à vos besoins spécifiques. Que ce soit par e-mail ou par téléphone, notre équipe répond toujours rapidement et est disponible pour discuter de vos besoins, fournir des conseils et trouver des solutions adaptées à votre entreprise.<br/>\
                \
                Contactez-nous dès aujourd'hui pour découvrir comment ATV Logistics peut vous aider à atteindre vos objectifs logistiques avec succès.<br/>\
                \
                Merci de votre confiance en ATV Logistics. Nous sommes impatients de vous servir",
                "apropos_3_part_title": "Nos partenaires",
                "apropos_3_val_title": "Nos valeurs",
                "apropos_3_val_desc": "On revient sur certains points qui nous sont très importants",
                "condition_desc": "Toutes nos prestations sont soumises aux conditions générales – belges d’expédition de 2005.\
                Le texte de ces conditions a été publié dans l’annexe au moniteur Belge du 24 juin 2005 sous le n° 0090237 et est disponible sur simple demande. Il peut aussi être visualisé en ligne sur notre site.\
                \
                Nos factures sont strictement payables à échéance.\
                Toute réclamation doit se faire par recommandé endéans les 8 jours.\
                Les factures non payées à l’échéance porteront de plein droit un intérêt de 12 % l’an, ainsi qu’une indemnité conventionnelle de 5 %.  Les intérêts de retard sont dus automatiquement, de plein droit et sans mise en demeure préalable, dès l’échéance de la facture.\
                \
                En cas de litige, les tribunaux de Liège sont seuls compétents."
            }   
        },
        pl: {
            "translation": {
                "logo": "ATV LOGISTICS",
                "menu_1": "STRONA GŁÓWNA",
                "menu_2": "MAGAZYNOWANIE I LOGISTYKA",
                "menu_3": "TRANSPORT DROGOWY I FLOTA",
                "menu_4": "O NAS",
                "service_compta": "Dział księgowości:",
                "address_1": "Magazyn: Rue de l'Abbaye 10b, B-4040 HERSTAL, Belgia",
                "address_2": "Siedziba główna: Rue Commandant Naessens, 47, B-4431 LONCIN, Belgia",
                "condition_gen": "Ogólne warunki",
                "and": "i",
                "home_image": "Twój specjalista w krajowym i międzynarodowym transporcie drogowym Magazynowanie i obsługa",
                "home_spec_line1": "Specjalizująca się w transporcie drogowym wszelkiego rodzaju towarów, ATV LOGISTICS obsługuje prawie wszystkie europejskie destynacje, w tym Turcję.",
                "home_spec_line2": "W partnerstwie z ArcelorMittal i New La Chaussée oferujemy również usługi magazynowania, obsługi i zarządzania logistyką.",
                "home_spec_line3": "Dzięki 40-letniemu doświadczeniu w przemyśle stalowym w Belgii i 20-letniemu w transporcie, oferujemy efektywne, bezpieczne i konkurencyjne rozwiązania.",
                "home_spec_line4": "Nasza organizacja wyróżnia się prostotą, dostępnością i elastycznością, a nasz dział handlowy i operacyjny dostępny w siedmiu językach optymalizuje koszty i obsługę klienta.",
                "home_spec_line5": "Aby uzyskać więcej informacji, nie wahaj się z nami skontaktować.",
                "home_bubble_1_title": "SPECJALISTA W TRANSPORCIE DROGOWYM I SPEDYCJI",
                "home_bubble_1_desc": "Nasi specjaliści ds. transportu oferują pełną ekspertyzę Twoich potrzeb<br/><br/>I zapewniają odpowiednie rozwiązania, z konkurencyjnymi cenami.<br/><br/>Od palety po pełną ciężarówkę",
                "home_bubble_2_title": "ŚLEDZENIE TWOJEGO TRANSPORTU W CZASIE RZECZYWISTYM",
                "home_bubble_2_desc": "Dzięki naszym ekspertom transportowym zapewniamy śledzenie Twoich transportów w czasie rzeczywistym.<br/><br/>Nasi specjaliści są w bezpośrednim kontakcie z kierowcami.",
                "home_bubble_3_title": "PLATFORMA LOGISTYCZNA",
                "home_bubble_3_desc": "Zlokalizowany w sercu sieci autostrad BENELUX - FRANCJA - NIEMCY<br/><br/>1500 M2 POWIERZCHNI MAGAZYNOWEJ (ponad 500 REGALÓW)<br/><br/>Parking dla ciężarówek: 700 M2",
                "home_dest": "Nasze główne destynacje",
                "home_fr": "Francja",
                "home_bl": "Belgia",
                "home_nl": "Holandia",
                "home_de": "Niemcy",
                "home_pl": "Polska",
                "home_it": "Włochy",
                "home_es": "Hiszpania",
                "home_dk": "Dania",
                "home_hu": "Węgry",
                "home_cz": "Czechy",
                "home_sk": "Słowacja",
                "home_lt": "Litwa",
                "home_ee": "Estonia",
                "home_lv": "Łotwa",
                "home_ro": "Rumunia",
                "home_fi": "Finlandia",
                "home_bg": "Bułgaria",
                "home_tr": "Turcja",
                "home_avatar_1_title":"Bernard",
                "home_avatar_1_desc": 'Specjalista Francja <i class="flag flag-fr"></i><br/>Polska <i class="flag flag-pl"></i>',
                "home_avatar_2_title": "Maria",
                "home_avatar_2_desc": 'Specjalista Włochy <i class="flag flag-it"></i>',
                "home_avatar_3_title": "Dmytro",
                "home_avatar_3_desc": 'Specjalista Ukraina <i class="flag flag-ua"></i>',
                "home_avatar_4_title": "Olivier",
                "home_avatar_4_desc": 'Kierownik transportu,<br/>logistyki i spedycji <i class="flag flag-be"></i> <i class="flag flag-germany"></i>',
                "home_avatar_5_title": "Johnny",
                "home_avatar_5_desc": 'Specjalista Finlandia <i class="flag flag-fi"></i>',
                "home_avatar_6_title": "Esin",
                "home_avatar_6_desc": 'Specjalista Turcja <i class="flag flag-tr"></i>',
                "home_avatar_7_title": "Martin",
                "home_avatar_7_desc": 'Specjalista Bułgaria <i class="flag flag-bg"></i>',
                "storage_title": "Magazynowanie i Logistyka",
                "storage_title_h3": "Prezentacja naszych obiektów",
                "storage_1_center_line1": "<span>1500 m2</span> powierzchni magazynowej (ponad <span>500 regałów</span>)",
                "storage_1_center_line2": "Parking dla ciężarówek: <span>700 m2</span>",
                "storage_1_center_line3": "Transport ATV Logistics, Twój Partner w Transporcie i Logistyce Towarów Ogólnych w Całej Europie.",
                "storage_2_sep_1_title": "Obiekt magazynowy",
                "storage_2_sep_1_desc": "Nasz obiekt magazynowy jest wyposażony, aby spełniać Twoje potrzeby w zakresie bezpiecznego magazynowania.<br/> Dzięki całkowitej powierzchni <span>1500 m2</span> i ponad <span>500 regałom</span> możemy efektywnie zarządzać różnorodnymi towarami.",
                "storage_2_sep_2_title": "Lokalizacja firmy",
                "storage_2_sep_2_desc": "Znajdujemy się w strategicznej lokalizacji, która pozwala nam efektywnie obsługiwać naszych klientów w całej Europie.<br /> Nasza lokalizacja zapewnia łatwy dostęp do głównych tras transportowych, co ułatwia logistykę Twoich towarów.",
                "flotte_1_title": "Transport drogowy i Flota",
                "flotte_1_desc": "Nasi eksperci ds. logistyki analizują szczegółowo Twoje potrzeby transportowe i oferują dostosowane rozwiązania w konkurencyjnych cenach. Specjalizując się w transporcie drogowym wszelkiego rodzaju ładunków, obsługujemy przesyłki od jednej palety do pełnej ciężarówki. Dostosowujemy nasze usługi, aby zapewnić szybkie i bezpieczne dostawy niemal w całej Europie.",
                "flotte_h3": "Prezentacja naszych różnych ciężarówek",
                "flotte_article_1_title": "1. Naczepa Tautliner plandeka",
                "flotte_article_1_desc1": "Długość: <span>13.60 m</span>, Szerokość: <span>2.45 m</span>, Wysokość: <span>2.75 m</span>",
                "flotte_article_1_desc2": "Maksymalna waga: <span>24 tony</span>",
                "flotte_article_2_title": "2. Naczepa Mega",
                "flotte_article_2_desc1": "Długość: <span>13.60 m</span>, Szerokość: <span>2.45 m</span>, Wysokość: <span>3 m</span>",
                "flotte_article_2_desc2": "Dostępna pojemność: <span>100 m3</span>",
                "flotte_article_2_desc3": "Maksymalna waga: <span>24 tony</span>",
                "flotte_article_3_title": "3. Jumbo lub Road train",
                "flotte_article_3_desc1": "<li>Długość: <span>15.40 m</span></li>\
                <li>Szerokość: <span>2.45 m</span></li>\
                <li>Wysokość: <span>3 m</span></li>",
                "flotte_article_3_desc2": "Dostępna pojemność: <span>120 m3</span>",
                "flotte_article_3_desc3": "Maksymalna dopuszczalna waga: <span>22 tony</span>",
                "flotte_article_4_title": "4. Ciężarówki z zagłębieniami (coilmulde truck)",
                "flotte_article_4_desc": "Używane do transportu kręgów stalowych stalowych lub papierowych.",
                "apropos_data_1_title": "DOŚWIADCZENIE",
                "apropos_data_1_desc": "Jesteśmy ekspertami w dziedzinie spedycji od ponad 10 lat.",
                "apropos_data_2_title": "OBSŁUGA KLIENTA",
                "apropos_data_2_desc": "Nasz zespół jest dostępny 24/7, aby odpowiadać na Twoje potrzeby i oferować wyjątkową obsługę.",
                "apropos_data_3_title": "SZYBKOŚĆ DOSTAWY",
                "apropos_data_3_desc": "Gwarantujemy szybkie terminy dostaw, aby sprostać Twoim najbardziej wymagającym wymaganiom transportowym.",
                "apropos_data_4_title": "KONKURENCYJNE CENY",
                "apropos_data_4_desc": "Nasze stawki są konkurencyjne na rynku spedycji, zachowując przy tym wysoką jakość usług.",
                "apropos_data_5_title": "STAŁE ŚLEDZENIE",
                "apropos_data_5_desc": "Zapewniamy stałe śledzenie Twoich przesyłek, aby informować Cię na każdym etapie procesu.",
                "apropos_data_6_title": "CERTYFIKACJA ISO 9001",
                "apropos_data_6_desc": "W trakcie uzyskiwania w celu ciągłego doskonalenia jakości naszych usług dla klientów.",
                "apropos_2_image_title": "O nas <span>nas</span>",
                "apropos_2_image_desc": 'Witamy w ATV Logistics, firmie rodzinnej założonej w Liège w Belgii w 2012 roku <i class="flag flag-be"></i><br/> Twój zaufany partner w dziedzinie transportu i spedycji',
                "apropos_3_hist_title": "Historia firmy",
                "apropos_3_hist_desc": "Założona przez Jean Vanderwaeren, doświadczonego profesjonalistę z ponad 40-letnim doświadczeniem w przemyśle stalowym, i Oliviera Vanderwaeren, absolwenta Uniwersytetu w Liège na kierunku Nauki o Zarządzaniu z 20-letnim doświadczeniem w tej dziedzinie, ATV Logistics korzysta z oświeconego przywództwa i solidnej wizji strategicznej.<br/>\
                \
                Dzięki wyjątkowej karierze w przemyśle stalowym i wykształceniu inżynierskiemu z École d'Ingénieur de Mons, Jean Vanderwaeren wnosi dogłębną wiedzę techniczną i dogłębne zrozumienie wyzwań logistycznych i operacyjnych napotykanych w sektorach przemysłowych. Jego pasja do innowacji i zaangażowanie w doskonałość kierują naszym podejściem do transportu, zapewniając skuteczne i dostosowane do potrzeb rozwiązania dla każdego klienta.<br/>\
                \
                Obok Jeana, Olivier wnosi perspektywę strategiczną i wiedzę z zakresu zarządzania biznesem, zdobyte podczas studiów na Uniwersytecie w Liège. Jego biegłość w naukach o zarządzaniu i zdolność do analizowania trendów rynkowych pozwalają nam przewidywać potrzeby klientów i rozwijać innowacyjne rozwiązania logistyczne.<br/>\
                \
                W ATV Logistics zobowiązujemy się do świadczenia najwyższej jakości usług transportowych, wspieranych przez zespół pełen pasji i zaangażowania. Naszym celem jest stać się Twoim preferowanym partnerem we wszystkich potrzebach transportowych i spedycyjnych, oferując niezawodne, elastyczne rozwiązania skoncentrowane na Twoich celach biznesowych.<br/>\
                \
                Rozumiemy znaczenie elastyczności w branży transportowej. Dlatego jesteśmy dostępni i łatwo osiągalni, aby spełniać Twoje specyficzne potrzeby. Niezależnie od tego, czy kontaktujesz się z nami przez e-mail, czy telefon, nasz zespół zawsze odpowiada szybko i jest dostępny, aby omówić Twoje potrzeby, udzielić porad i znaleźć rozwiązania dostosowane do Twojego biznesu.<br/>\
                \
                Skontaktuj się z nami już dziś, aby dowiedzieć się, jak ATV Logistics może pomóc Ci osiągnąć Twoje cele logistyczne z sukcesem.<br/>\
                \
                Dziękujemy za zaufanie do ATV Logistics. Czekamy na możliwość obsługi.",
                "apropos_3_part_title": "Nasi partnerzy",
                "apropos_3_val_title": "Nasze wartości",
                "apropos_3_val_desc": "Podkreślamy kilka punktów, które są dla nas bardzo ważne",
                "condition_desc": "Wszystkie nasze usługi są objęte Ogólnymi Warunkami Belgijskiego Spedycji z 2005 roku.\
                Tekst tych warunków został opublikowany w załączniku do belgijskiego Dziennika Urzędowego w dniu 24 czerwca 2005 roku pod numerem 0090237 i jest dostępny na żądanie. Można go również zobaczyć online na naszej stronie internetowej.\
                \
                Nasze faktury są ściśle płatne w terminie.\
                Wszelkie reklamacje muszą być zgłaszane listem poleconym w ciągu 8 dni.\
                Niezapłacone faktury w terminie będą automatycznie obciążane odsetkami w wysokości 12% rocznie oraz konwencjonalnym odszkodowaniem w wysokości 5%. Odsetki za opóźnienie są automatycznie należne, z mocy prawa i bez uprzedniego powiadomienia, od daty wymagalności faktury.\
                \
                W przypadku sporu, sądy w Liège będą miały wyłączną jurysdykcję."
            }
        }
    }
});

export default i18next;
