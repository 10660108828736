import {useEffect, useState} from 'react';

import { ImageList, ImageListItem } from '@mui/material';
import { Container, Typography, Grid, Box, Card, CardContent, CardHeader } from '@mui/material';

import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

import AwesomeSlider from 'react-awesome-slider';
import ReactPlayer from 'react-player';
import ScrollToTop from '../utils/scrollTop';

import video from '../images/video_high.mp4';
import video2 from '../images/video2.mp4';


import leftCamionImg from '../images/leftcamion.png';

import apropos1 from '../images/apropos1.jpeg';
import apropos2 from '../images/apropos2.jpeg';
import apropos3 from '../images/apropos3.jpeg';

import logo1 from '../images/arcellor.png';
import logo2 from '../images/dbschenker.png';
import logo3 from '../images/Arc_logo.png';
import logo4 from '../images/leroylogistique.png';

import parkingImg from '../images/camionparking.jpeg';
import i18next from 'i18next';

const images = [apropos1, apropos2, apropos3];

const logos = [logo1, logo2, logo3, logo4];
  
export default (props) => {

    const valeursData = [
        { title: i18next.t("apropos_data_1_title"), description: i18next.t("apropos_data_1_desc") },
        { title: i18next.t("apropos_data_2_title"), description: i18next.t("apropos_data_2_desc") },
        { title: i18next.t("apropos_data_3_title"), description: i18next.t("apropos_data_3_desc") },
        { title: i18next.t("apropos_data_4_title"), description: i18next.t("apropos_data_4_desc") },
        { title: i18next.t("apropos_data_5_title"), description: i18next.t("apropos_data_5_desc") },
        { title: i18next.t("apropos_data_6_title"), description: i18next.t("apropos_data_6_desc") }
      ];

    return <div className="page">
        <div className="apropos _2">
            <div className='pillow'></div>
            <h1 dangerouslySetInnerHTML={{__html: i18next.t("apropos_2_image_title")}}></h1>
            <p dangerouslySetInnerHTML={{__html: i18next.t("apropos_2_image_desc")}}></p>
        </div>
        <div className='apropos _3'>   
        <h1>{i18next.t("apropos_3_hist_title")}</h1>
        <p dangerouslySetInnerHTML={{__html: i18next.t("apropos_3_hist_desc")}}></p>
        <Box id="logoCollection" sx={{ py: 4 }}>
            <h1>{i18next.t("apropos_3_part_title")}</h1>
            <Grid className="test" container justifyContent="center" alignItems="center" sx={{ mt: 0.5, opacity: 0.6}}>
                {logos.map((logo, index) => (
                <Grid item key={index} sx={{marginLeft: 2, marginRight: 2}}>
                    <img
                    className={`logos logo${index + 1}`}
                    src={logo}
                    alt={`${index + 1}`}
                    />
                </Grid>
                ))}
            </Grid>
            </Box>
            <div id="testimonials" class="testimonials-container">
                <div class="content-wrapper">
                    <h1>{i18next.t("apropos_3_val_title")}</h1>
                    <h4>{i18next.t("apropos_3_val_desc")}</h4>
                    <div class="grid-container">
                        {valeursData.map((valeur, index) => (
                            <div class="grid-item" key={index}>
                                <div class="card">
                                    <h2>{valeur.title}</h2>
                                    {valeur.description && <p>{valeur.description}</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className="apropos _4">
            <ReactPlayer url={video} controls/>
            <ReactPlayer url={video2} controls/>
        </div>
    </div>
};