import logo from '../images/design-null.png';
import MigrationMap from './MigrationMap';
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import {FormControl, Select, MenuItem, ToggleButtonGroup, ToggleButton} from '@mui/material';
import {useState, useEffect} from 'react';

import HomePage from '../routes/Home';
import AboutPage from '../routes/Apropos';
import StockagePage from '../routes/Stockage';
import FlottePage from '../routes/Flotte';
import ErrorPage from '../routes/Error';
import ConditionsPage from '../routes/Conditions';

import mapsImg from '../images/maps.png';
import phoneImg from '../images/phone.png';
import emailImg from '../images/mail.png';
import linkedinImg from '../images/linkedin.png';

import '../css/App.scss';
import ScrollToTop from '../utils/scrollTop';


import i18next from '../translations/config';

let countries = [
  {
    code: "fr",
    name: "Français",
    country_code: "FR",
  },
  {
    code: "en",
    name: "English",
    country_code: "EN",
  },
  {
    code: "pl",
    name: "Polski",
    country_code: "PL",
  },
];

function App() {

  const [language, setLanguage] = useState(countries[0].code);

  const [menu, setMenu] = useState(false);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
    i18next.changeLanguage(event.target.value.toLowerCase());
    localStorage.setItem("lng", event.target.value.toLowerCase());
  };

  useEffect(() => {
    const lng = localStorage.getItem("lng");
    if(lng){
      setLanguage(localStorage.getItem("lng"));
      i18next.changeLanguage(localStorage.getItem("lng"));
    }
  }, []);


  return (
    <div className="App">
      <ScrollToTop />
      <header id="top">
        <Link to="/#top" className="logo">
          <img src={logo} alt="logo" />
          <h1>{i18next.t("logo")}</h1>
          {/* <p>Transportation Solutions</p> */}
        </Link>


        <nav className={(menu)?"activate":""}>
          <ul>
            <li><Link onClick={() => {
              setMenu(false);
            }} to="/">{i18next.t("menu_1")}</Link></li>
            <li><Link onClick={() => {
              setMenu(false);
            }} to="/stockage">{i18next.t("menu_2")}</Link></li>
            <li><Link onClick={() => {
              setMenu(false);
            }} to="/flotte">{i18next.t("menu_3")}</Link></li>
            <li><Link onClick={() => {
              setMenu(false);
            }} to="/about_us">{i18next.t("menu_4")}</Link></li>
            <li className="toggleLanguage">
              <ToggleButtonGroup
                color="primary"
                value={language}
                exclusive
                className="toggle"
                onChange={handleChangeLanguage}
                aria-label="Platform"
              >
                {countries.map((country) => (
                  <ToggleButton className="togglebutton" value={country.code}>{country.country_code}</ToggleButton>
                ))}
              </ToggleButtonGroup>
            </li>
          </ul>
        </nav>

        <button className={"burger "+(menu?"activate":"")} onClick={(e) => {
          setMenu(value => !value);
        }}>
          <div className="elt"></div>
          <div className="elt"></div>
          <div className="elt"></div>
        </button>


        <div className={"languageSelector "+(menu?"activate":"")}>
          <Select
            value={language}
            onChange={handleChangeLanguage}
            style={{ color: '#fff' }}
            displayEmpty
            inputProps={{ 'aria-label': 'Select Language'}}
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.country_code}
              </MenuItem>
            ))}
          </Select>
        </div>
      </header>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about_us" element={<AboutPage />}/>
        <Route path="/stockage" element={<StockagePage />}/>
        <Route path="/flotte" element={<FlottePage />}/>
        <Route path="/conditions" element={<ConditionsPage />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>

      <footer>
        <div className="left-flex">
          <div className="logo">
            <img src={logo} alt="logo" />
            <h1>{i18next.t("logo")}</h1>
          </div>
          <div className="infos">
            <div className="left">
              <p style={{display: "inline-block"}}><img src={emailImg} style={{float:"left"}}alt="Email: "/><a href="mailto:atvlogistics.priceoffer@gmail.com">atvlogistics.priceoffer@gmail.com</a> {i18next.t("and")} <a href="mailto:info2@atvlogistics.be">info2@atvlogistics.be</a><br/>{i18next.t("service_compta")} <a href="mailto:atvlogistics.invoices@gmail.com">atvlogistics.invoices@gmail.com</a></p>
              <p><img src={mapsImg} alt="Address:" />{i18next.t("address_1")}<br/>{i18next.t("address_2")}</p>
            </div>
            <div className="right">
              <p><img src={phoneImg} alt="Phone: "/> 0032 43 80 90 46<br/>0032 470 59 39 72</p>
              <p><img src={linkedinImg} alt="Linkedin: "/><a href="https://www.linkedin.com/company/atvlogistics/">atvlogistics</a></p>
            </div>
          </div>
          <h4><Link to="/conditions">{i18next.t("condition_gen")}</Link></h4>
        </div>
        <div className="line"></div>
        <div className="description">
          <p>Copyright © ATV LOGISTICS - Transportation Solutions 2024 - Tva/Vat: BE0.508 719 864</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
