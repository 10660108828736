import React from 'react';

import carrousselImg from '../images/carroussel.jpeg';

import googleMapImg from '../images/apropos2.jpeg';

import testImg1 from '../images/tst/12.jpeg';
import testImg2 from '../images/tst/15.jpeg';
import testImg3 from '../images/camion_replacement.jpeg';
import testImg4 from '../images/tst/57.jpeg';
import testImg5 from '../images/last_camion.jpeg';
import testImg6 from '../images/stockage1.jpeg';
import testImg7 from '../images/stockage2.jpeg';
import testImg8 from '../images/stockage3.jpeg';
import i18next from 'i18next';

const StoragePage = (props) => {
    return (
        <div className="page">
            <div className="stockage _1">
                <div className='pillow'></div>
                <h1>{i18next.t("storage_title")}</h1>
                <h3>{i18next.t("storage_title_h3")}</h3>
                <img src={googleMapImg} alt="truck"/>
                <div className="center">
                    <p dangerouslySetInnerHTML={{__html: i18next.t("storage_1_center_line1")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("storage_1_center_line2")}}></p>
                    <p dangerouslySetInnerHTML={{__html: i18next.t("storage_1_center_line3")}}></p>
                </div>
            </div>
            <div className="stockage _2">
                <div className="div-sep">
                    <div className="sep">
                        <h3>{i18next.t("storage_2_sep_1_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("storage_2_sep_1_desc")}}></p>
                    </div>
                    <div className="sep">
                        <h3>{i18next.t("storage_2_sep_2_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("storage_2_sep_2_desc")}}></p>
                    </div>
                </div>
                
                <div className="images">
                    <img src={testImg1} alt="image of storage facility" />
                    <img src={testImg2} alt="image of storage facility" />
                    <img src={testImg3} alt="image of storage facility" />
                    <img src={testImg4} alt="image of storage facility" />
                    <img src={testImg8} alt="image of storage facility" />
                    <img src={testImg7} alt="image of storage facility" />
                    <img src={testImg6} alt="image of storage facility" />
                    <img src={testImg5} alt="image of storage facility" />
                </div>
            </div>
        </div>
    );
}

export default StoragePage;
