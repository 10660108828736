import {useEffect, useState} from 'react';

import conditionImg from "../images/conditions.webp";
import i18next from 'i18next';

export default (props) => {

    return <div className="page">
        <div className="pillow"></div>
        <div className="condition _1">
            <div className="left-flex">
                <img src={conditionImg} alt="condition" />
            </div>
            <div className="right-flex">
                <h1>{i18next.t("condition_gen")}</h1>
                <p>{i18next.t("condition_desc")}</p>
            </div>
        </div>
    </div>
};