import {useEffect, useState} from 'react';

import AwesomeSlider from 'react-awesome-slider';

import MigrationMap from '../components/MigrationMap';

import carrousselImg from '../images/carroussel12.png';
import carroussel2Img from '../images/camion_center.jpeg';

import carteImg from "../images/carte_vierge.png";
import logo from '../images/design-null.png';
import solutionImg from '../images/solution.png';
import operatorImg from '../images/operator.png';


import logoShirt from '../images/flotte/97.jpeg';
import jeanImg from '../images/jean.png';
import leftCamionImg from '../images/leftcamion.png';
import camionImg from '../images/flotte/18.jpeg';
import cochonImg from '../images/cochon.jpeg';
import walletImg from '../images/wallet.png';



import phoneImg from '../images/phone.png';
import emailImg from '../images/mail.png';

import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import { Typography, Card, CardActionArea, CardMedia, CardContent} from '@mui/material';

import 'react-awesome-slider/dist/styles.css';
import "react-awesome-slider/dist/custom-animations/fold-out-animation.css";
import i18next from 'i18next';


const images = [carroussel2Img];


export default (props) => {

    return (<div className="page HomePage">
        <div className="main _1">
            <AwesomeSlider  infinite={false} mobileTouch={false} className='slider'>
                {images.map((image) => {
                    return (<div data-src={image} ><h4>{i18next.t("home_image")}</h4></div>)
                })}
            </AwesomeSlider>
        </div>
        <div className="main _5">
            {/* <h2>SPECIALISTE DU TRANSPORT ROUTIER</h2> */}
            <p>{i18next.t("home_spec_line1")}</p>
            <p>{i18next.t("home_spec_line2")}</p>
            <p>{i18next.t("home_spec_line3")}</p>
            <p>{i18next.t("home_spec_line4")}</p>
            <p>{i18next.t("home_spec_line5")}</p>
        </div>
        <div className="main _3">
            {/* <h2>SPECIALISTE DU TRANSPORT ROUTIER</h2> */}
            <div className="title">
                <div className="left-flex">
                    <div className="img">
                        <img src={logoShirt} alt="logo" />
                    </div>
                </div>
                <div className="right-flex">
                    {/* <p>Chez ATV Logistics, nous sommes fiers d’être votre spécialiste du transport routier, offrant des services sur mesure qui répondent à vos besoins spécifiques.<br />
                    Notre équipe dédiée travaille sans relâche pour garantir que vos marchandises arrivent à destination en toute sécurité, à temps et dans les meilleures conditions.</p> */}
                    <div className="bubbles">
                        <div className="bubble">
                            <h3>{i18next.t("home_bubble_1_title")}</h3>
                            <p dangerouslySetInnerHTML={{__html:i18next.t("home_bubble_1_desc")}}></p>
                        </div>
                        <div className="bubble">
                            <h3>{i18next.t("home_bubble_2_title")}</h3>
                            <p dangerouslySetInnerHTML={{__html:i18next.t("home_bubble_2_desc")}}></p>
                        </div>
                        <div className="bubble">
                            {/* <img src={walletImg} alt="wallet"/> */}
                            <h3>{i18next.t("home_bubble_3_title")}</h3>
                            <p dangerouslySetInnerHTML={{__html:i18next.t("home_bubble_3_desc")}}></p>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
        <div className="main _2">
            <div className="left-flex">
                <h5>{i18next.t("home_dest")}</h5>
                <ul>
                <li>{i18next.t("home_fr")} <i class="flag flag-france"></i></li>
                <li>{i18next.t("home_bl")} <i class="flag flag-belgium"></i></li>
                <li>{i18next.t("home_nl")} <i class="flag flag-netherlands"></i></li>
                <li>{i18next.t("home_de")} <i class="flag flag-germany"></i></li>
                <li>{i18next.t("home_pl")} <i class="flag flag-poland"></i></li>
                <li>{i18next.t("home_it")} <i class="flag flag-italy"></i></li>
                <li>{i18next.t("home_es")} <i class="flag flag-spain"></i></li>
                <li>{i18next.t("home_dk")} <i class="flag flag-denmark"></i></li>
                <li>{i18next.t("home_hu")} <i class="flag flag-hungary"></i></li>
                <li>{i18next.t("home_cz")} <i class="flag flag-cz"></i></li>
                <li>{i18next.t("home_sk")} <i class="flag flag-slovenia"></i></li>
                <li>{i18next.t("home_lt")} <i class="flag flag-lt"></i></li>
                <li>{i18next.t("home_ee")} <i class="flag flag-ee"></i></li>
                <li>{i18next.t("home_lv")} <i class="flag flag-lv"></i></li>
                <li>{i18next.t("home_ro")} <i class="flag flag-ro"></i></li>
                <li>{i18next.t("home_fi")} <i class="flag flag-fi"></i></li>
                <li>{i18next.t("home_bg")} <i class="flag flag-bg"></i></li>
                <li>{i18next.t("home_tr")} <i class="flag flag-tr"></i></li>
                </ul>

                <img className="carte" src={carteImg} alt="image"/>
             
            </div>
            <div className="right-flex">
                <img className="carte" src={carteImg} alt="image"/>
            </div>
        </div>
        
        <div className="main _4">
            <div className="support">
                <h2>Notre équipe de spécialistes</h2>
                <div className="bubble">
                    {/* <p className="description">We are ready to meet you and to assist you in all aspects of your logistics and are willing to answer all your questions and concerns.</p> */}
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_1_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_1_desc")}}></p>

                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics12@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+33 768867151</p>
                        </div>
                    </div> 
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_2_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_2_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_3_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_3_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_4_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_4_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_5_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_5_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_6_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_6_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                    <div className="avatar">
                        <h3>{i18next.t("home_avatar_7_title")}</h3>
                        <p dangerouslySetInnerHTML={{__html: i18next.t("home_avatar_7_desc")}}></p>
                        <div className="contact">
                            <p className="mail"><img src={emailImg} alt="mail: "/>atvlogistics.priceoffer@gmail.com</p>
                            <p className="tel"><img src={phoneImg} alt="tel: "/>+32 43809046</p>
                        </div>              
                    </div>
                </div>
            </div>
        </div>
  
    </div>)
};